<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.mobile"
    width="800"
    scrollable
    v-model="dialog"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        class="py-3"
        tile
        outlined
        v-bind="attrs"
        v-on="on"
        >
        Editar
      </v-btn>
    </template>

    <v-card
      v-if="dialog"
      style="display: flex; flex-direction: column"
      >
      <v-card-title
        class="justify-center"
        >
        Editar perfil
        <v-btn
          icon
          class="mr-4"
          @click="dialog = false"
          style="position: absolute; right: 0"
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        class="pt-3"
        >
        <v-form
          style="margin-bottom: 60px"
          class="d-flex flex-column"
          v-model="valid"
          ref="form"
          lazy-validation
          >
          <v-text-field
            label="Nombre de usuario"
            v-model="attributes.slug"
            :rules="[ v => !!v || 'Campo obligatorio' ]"
            outlined
            flat
            ></v-text-field>

          <v-text-field
            label="Nombre y apellido"
            v-model="attributes.name"
            :rules="[ v => !!v || 'Campo obligatorio' ]"
            outlined
            flat
            ></v-text-field>

          <v-text-field
            label="Bio"
            v-model="attributes.description"
            outlined
            flat
            ></v-text-field>
        </v-form>

        <div
          style="position: absolute; bottom: 0; left: 0"
          class="fill-width py-2 px-6"
          >
          <v-btn
            :loading="loading"
            :disabled="loading"
            block
            class="rounded-lg"
            color="primary"
            @click="edit"
            >
            Editar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { EditProfile } from '@/graphql/mutations/profiles'

export default {
  data: () => ({
    valid: true,
    dialog: false,
    loading: false,
    attributes: {
      slug: null,
      name: null,
      description: null
    }
  }),

  props: {
    profile: {
      type: Object,
      required: true
    }
  },

  created () {
    this.attributes.slug = this.profile.slug
    this.attributes.name = this.profile.name
    this.attributes.description = this.profile.description
  },

  methods: {
    edit () {
      if (this.$refs.form.validate()) {
        this.loading = true

        this.$apollo.mutate({
          mutation: EditProfile,
          variables: {
            input: {
              id: this.profile.id,
              attributes: {
                ...this.attributes
              }
            }
          }
        }).then ( res => {
          this.loading = false
          this.$router.go()
        })
      }
    }
  }
}
</script>
